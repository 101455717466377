const NUTRITION_INFO_MSG = '2000 calories a day is used for general nutrition advice, but calorie needs vary. Additional nutrition information available upon request.';
const MAX_TOPPINGS_ALLOWED = 10;
const MAX_TOPPINGS_ALLOWED_FOR_PAN_CRUST = 5;
const MAX_TOPPINGS_ALLOWED_FOR_BNY_CRUST = 3;
const BNY_CRUST_ID = 'rLU7eRzeaSOK-lLxzX78qw';
const YUM_BNY_CRUST_ID = 'BASE451NY';

export const BNY_CYO_ID = 'vFmRk_1not_PRjWy9cuLjQ';
export const PIZZA_MELT_CRUST = 'Pizza Melt Crust';
export const DETROIT_PIZZA = 'Detroit-Style Pizza';
export const BIG_NEW_YORKER = 'Big New Yorker';
export const BIG_NEW_YORKER_DOUBLE_PEPPERONI = 'The Big New Yorker - Double Pepperoni';
export const HOT_HONEY_PARTIAL = 'Hot Honey';
export const TAVERN_PIZZA = 'Tavern';
export const CRISPY_CUPPED_PEPPERONI = 'Crispy Cupped Pepperoni';
export const GRILLED_SIRLOIN_STEAK = 'Grilled Sirloin Steak';
export const LEGAL_DISCLAIMER = 'Product availability, prices & participation vary. Additional charges & exclusions may apply.';
export const YUM_GLUTEN_FREE_CRUST_ID = 'CLSS001P|BASE701GF';
export const YUM_EXTRA_CHEESE_MODIFIER_WEIGHT_CODE = 'CLSS001P|TOPP200XC';
export const YUM_MODIFIER_PIZZA_CHEESE = 'modifier_pizza_cheese';
export const YUM_SLOT_PIZZA_CHEESE = 'slot_pizza_cheese';

// WEB-426, WEB-970
export const HIDE_EXTRA_TOPPINGS = [CRISPY_CUPPED_PEPPERONI, GRILLED_SIRLOIN_STEAK];

export default {
  NUTRITION_INFO_MSG,
  MAX_TOPPINGS_ALLOWED,
  MAX_TOPPINGS_ALLOWED_FOR_PAN_CRUST,
  MAX_TOPPINGS_ALLOWED_FOR_BNY_CRUST,
  BNY_CRUST_ID,
  YUM_BNY_CRUST_ID
};
