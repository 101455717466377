import React from 'react';
import {
  Cart,
  LoyaltyOfferOperation,
  useApplyLoyaltyOfferMutation,
  useRemovePromoCodeMutation,
  YumCart,
  orderApiCart,
  PromotionDefinition,
  DiningOccasion,
  ItemQuantityRequirement,
  SubtotalRequirement
} from '@pizza-hut-us-development/client-core';
import { useDispatch } from 'react-redux';
import { closeCartRail, openCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import {
  findRedemptionWarningFromPromoOrCode,
  isRedemptionWarning,
  isResolvableWarning,
  isYumCart
} from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';
import {
  openLocalizationRail, openModal, switchToCarryout, switchToDelivery
} from '@/localization/actions';
import telemetry from '@/telemetry';
import { toggleRail } from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';
import { formatPrice } from '../../CartItem/helpers/cartItemHelpers';

const useCodeRedemption = () => {
  const dispatch = useDispatch();

  const [removePromoCode] = useRemovePromoCodeMutation();
  const [applyLoyaltyOffer] = useApplyLoyaltyOfferMutation();

  const removePromotionFromCart = async (promotionId: string, cart: Cart) => {
    // Check if this is a standard promo, or if it has an associated loyaltyId
    let loyaltyId: string | undefined;
    // TODO: Remove typecast when CC type is updated
    const warningMatch = (cart as YumCart).warnings?.find((warning) => {
      if (!isRedemptionWarning(warning)) return false;
      return warning.promotionId === promotionId;
    });

    // Have to type-check again here because TS is dumb sometimes
    if (isRedemptionWarning(warningMatch)) {
      loyaltyId = warningMatch.loyaltyOffer?.loyaltyOfferId;
      // If there's a loyaltyId, we need to remove the promo via the loyalty endpoint
      if (loyaltyId) {
        try {
          await applyLoyaltyOffer({
            loyaltyOfferId: loyaltyId,
            operation: LoyaltyOfferOperation.REMOVE
          });
        } catch (err) {
          telemetry.addNoticeError(new Error('Error removing loyalty promotion from cart'));
        }
      } else {
        try {
          await removePromoCode(warningMatch.code);
        } catch (err) {
          telemetry.addNoticeError(new Error('Error removing promotion from cart'));
        }
      }
    }
  };

  const handleRedemptionWarnings = async (redemptionCode: string, cart?: Cart, definition?: PromotionDefinition, codeType?: 'loyalty' | 'promo'): Promise<JSX.Element | null> => {
    if (!cart) return null;
    if (!isYumCart(cart)) return null;

    let promotionId = redemptionCode;
    if (codeType === 'loyalty') {
      // If it's a loyalty code, find the associated promotionId
      // This will only happen for the initial pass, after we're in the redemption flow we've converted to using the promotionId
      // TODO: remove any cast once CC cart type is updated
      promotionId = (cart as YumCart).appliedPromotions?.find((promotion) => {
        const loyaltyOfferId = promotion.loyaltyOfferIds?.[0];
        return loyaltyOfferId === redemptionCode;
      })?.loyaltyOfferIds?.[0] ?? '';
    }

    // Find a warning with this promotionId
    const associatedWarning = findRedemptionWarningFromPromoOrCode(cart, promotionId);

    if (associatedWarning) {
      let promotionDefinition = definition;
      if (!promotionDefinition) {
        // Find our associated promotion definition
        promotionDefinition = await dispatch(orderApiCart.endpoints.getPromotion.initiate(promotionId))
          .then((response: any) => {
            if (response.data) return response.data as PromotionDefinition;
            return undefined;
          });
      }

      // TODO: remove type cast once CC CartWarning type is updated
      // eslint-disable-next-line no-underscore-dangle
      const warningReasonTypes = associatedWarning.reasons.map((reason: any) => reason?.__typename);
      // Base modal details, used if none of the specific catches below trigger
      let modalDetails: ModalContent = {
        title: 'Coupon Not Applied',
        body: 'That offer is not available at this time',
        cta: {
          text: 'Ok'
        },
        onClose: () => removePromotionFromCart(promotionId, cart)
      };

      if (warningReasonTypes.includes('UserStatusRequirementNotMet')) {
        // Only available for logged in user
        modalDetails = {
          ...modalDetails,
          body: 'Please sign in and try again',
          cta: {
            text: 'Sign in',
            callback: () => {
              dispatch(toggleRail(RailType.SIGN_IN));
            }
          },
          altCta: {
            text: 'Cancel'
          }
        };
      } else if (warningReasonTypes.includes('DiningOccasionRequirementNotMet')) {
        const reqOccasion = cart.occasion === DiningOccasion.CARRYOUT ? DiningOccasion.DELIVERY : DiningOccasion.CARRYOUT;
        const formattedOccasion = reqOccasion.toLowerCase();
        // Only available for other occasion
        modalDetails = {
          ...modalDetails,
          body: `This coupon is only valid for ${formattedOccasion} orders. Please swap to ${formattedOccasion} and try again.`,
          cta: {
            text: `Swap to ${formattedOccasion}`,
            callback: () => {
              dispatch(openLocalizationRail());
              dispatch(closeCartRail());
              if (cart.occasion === DiningOccasion.CARRYOUT) {
                dispatch(switchToDelivery());
              } else {
                dispatch(switchToCarryout());
              }
            }
          },
          altCta: {
            text: 'Cancel'
          }
        };
      } else if (warningReasonTypes.includes('PromotionNotCombinable')) {
        // Conflicting promotion already in cart
        modalDetails = {
          ...modalDetails,
          body: 'Sorry this offer can not be combined with another offer in your cart'
        };
      } else if (warningReasonTypes.includes('ItemExclusionRequirementNotMet')) {
        // Conflicting item in cart
        modalDetails = {
          ...modalDetails,
          body: 'Sorry this offer can not be combined with an item in your cart'
        };
      }

      // TEMP: For initial rollout
      const errorMessage = promotionDefinition?.privateMetafields.find((meta) => meta.key === 'errorMessage')?.value;
      let couponWarning = errorMessage || 'Once you add the required items, your offer will be applied to the cart';
      let shouldReturnCouponWarning = false;
      if (warningReasonTypes.includes('ItemPresenceRequirementNotMet')) {
        // Requirement missing from cart
        // TODO: Route user to redemption menu once CC work is done
        // () => router.push(`/redeem?red=${promotionId}&fl=req`)
        shouldReturnCouponWarning = true;
      } else if (warningReasonTypes.includes('ItemQuantityRequirementNotMet')) {
        // More items in cart required

        const quantReq = (promotionDefinition?.requirements.find((req) => req.type === 'ItemQuantityRequirement') as ItemQuantityRequirement)?.minimumTotalItems;
        const addItemCount = ` ${(quantReq - cart.items.length)}` || '';

        couponWarning = `Once you add${addItemCount} more items to the cart, your offer will be applied`;
        shouldReturnCouponWarning = true;
      } else if (warningReasonTypes.includes('SubtotalRequirementNotMet')) {
        // Minimum subtotal not met
        const reqSubtotal = (promotionDefinition?.requirements.find((req) => req.type === 'SubtotalRequirement') as SubtotalRequirement)?.minimumSubtotal.amount;
        let minAmountText = 'required';
        if (reqSubtotal) {
          const formattedSubtotal = formatPrice(reqSubtotal);
          minAmountText = `of ${formattedSubtotal} required`;
        }

        couponWarning = `Your cart's subtotal is below the minimum amount ${minAmountText}`;
        shouldReturnCouponWarning = true;
      } else if (warningReasonTypes.includes('PromotionEffectTargetNotInCart')) {
        // Effect missing from cart
        // TODO: Include route for user on warning text to redemption menu once CC work is done
        // router.push(`/redeem?red=${promotionId}&fl=eff`)
        shouldReturnCouponWarning = true;
      } else if (warningReasonTypes.includes('PromotionIsInvalid')) {
        // TEMP til Yum fix - PromotionIsInvalid can contain a valid reason we need to handle
        // eslint-disable-next-line no-underscore-dangle
        const associatedReason = associatedWarning.reasons.find((reason) => (reason as any).__typename === 'PromotionIsInvalid');
        if (associatedReason?.message === 'NO_DISCOUNTABLE_SUBTOTAL') {
          const reqSubtotal = (promotionDefinition?.requirements.find((req) => req.type === 'SubtotalRequirement') as SubtotalRequirement)?.minimumSubtotal.amount;
          let minAmountText = 'required';
          if (reqSubtotal) {
            const formattedSubtotal = formatPrice(reqSubtotal);
            minAmountText = `of ${formattedSubtotal} required`;
          }
          couponWarning = `Your cart's subtotal is below the minimum amount ${minAmountText}`;
          shouldReturnCouponWarning = true;
        }
      }

      if (!isResolvableWarning(associatedWarning)) {
        dispatch(openModal(modalDetails));
      }
      if (shouldReturnCouponWarning) {
        return <span>Coupon incomplete: {couponWarning}</span>;
      }
      return null;
    }

    // Code was successfully applied
    // TODO: Add in this check after OG work is done
    // if ((cart as YumCart).appliedPromotions?.find((promotion) => promotion.promotionId === promotionId)) {
    dispatch(openCartRail());
    // }
    return null;
  };
  return [
    handleRedemptionWarnings
  ];
};

export default useCodeRedemption;
