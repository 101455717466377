import { useCustomerInfoQuery } from '@pizza-hut-us-development/client-core';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { userDomainSelectors } from '@/header/userDomainSelectors';
import { RootState } from '@/rootStateTypes';
import isOptimizelyFeatureEnabled from '../../../../optimizely/utils/isOptimizelyFeatureEnabled';
import { actions as profileActions, selectors } from '@/account/profile/profile.slice';
import { customerDetailsFailure, customerDetailsSuccess } from '@/header/actions';
import customerService from '@/services/customerService';
import { transformCustomerInfo, transformPromos } from './transformCustomerInfo';
import { useTemporaryCCContext } from '../temporaryCCContext';

const useCCCustomerDetails = () => {
  // Prerequisites
  const isUseCustomerInfoQueryHookEnabled = isOptimizelyFeatureEnabled('fr-web-3611-use-custmer-info-query-hook');
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const { isOptimizelyApiLoaded } = useTemporaryCCContext();
  // User/App Status
  const dispatch = useDispatch();
  const isAuthenticatedUser = useSelector(userDomainSelectors.isAuthenticatedUser);
  const [getCustomerLoyalty, setGetCustomerLoyalty] = useState(true);
  // CC Hook
  const {
    data,
    isLoading,
    isError,
    refetch: refetchCustomerInfo,
    isUninitialized,
    isSuccess
  } = useCustomerInfoQuery(undefined, {
    skip: !isUseCustomerInfoQueryHookEnabled || !isAuthenticatedUser || !isOptimizelyApiLoaded
  });

  useEffect(() => {
    if (!data) return;
    if (!isLoading) {
      if (isError) {
        dispatch(profileActions.error());
        dispatch(customerDetailsFailure());
      }

      if (data.email) {
        const result = data && transformCustomerInfo(data);
        dispatch(profileActions.success(result));
        dispatch(
          customerDetailsSuccess({
            ...result,
            id: data.customerId,
            ...transformPromos(data)
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Handles Customer Loyalty
  useEffect(() => {
    if (getCustomerLoyalty && isOptimizelyApiLoaded) {
      const getLoyaltyInfo = async () => {
        const loyalty = await customerService.getCustomerLoyalty();
        if (loyalty?.coupons) {
          dispatch(profileActions.updateCoupons(loyalty.coupons));
        }
      };
      getLoyaltyInfo();
      setGetCustomerLoyalty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomerLoyalty, isOptimizelyApiLoaded]);

  // see if this is double call
  useEffect(() => {
    if (!isUninitialized && isAuthenticatedUser && !isSuccess) {
      refetchCustomerInfo();
      if (!getCustomerLoyalty) setGetCustomerLoyalty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isYumEcomm]);
};

export default useCCCustomerDetails;
