import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line max-len
import phdApiAccountManagementService from '@/services/accountManagementService/phdApiAccountManagementService';
import { actions as profileActions, selectors } from '@/account/profile/profile.slice';
import { customerDetailsFailure, customerDetailsSuccess } from '@/header/actions';
import { UseCustomerDetails } from '@/account/profile/hooks/types';
import customerService from '@/services/customerService';
import isOptimizelyFeatureEnabled from '../../../../optimizely/utils/isOptimizelyFeatureEnabled';
import { RootState } from '@/rootStateTypes';
import { NATIONAL } from '@/localization/constants';

const useCustomerDetails: UseCustomerDetails = () => {
  const loading = useSelector(selectors.loading);
  const error = useSelector(selectors.error);
  const profile = useSelector(selectors.profile);
  const dispatch = useDispatch();
  const isUseCustomerInfoQueryHookEnabled = isOptimizelyFeatureEnabled('fr-web-3611-use-custmer-info-query-hook');
  const isLocalized = useSelector((state: RootState) => state.domain.localization.localizedStore !== NATIONAL);

  const fetchCustomerDetails = async () => {
    if (isUseCustomerInfoQueryHookEnabled && isLocalized) {
      return;
    }

    dispatch(profileActions.loading());

    const { error: apiError, result } = await phdApiAccountManagementService.myAccount.getCustomer();

    const loyalty = await customerService.getCustomerLoyalty();

    if (apiError) {
      dispatch(profileActions.error());
      dispatch(customerDetailsFailure());
    }

    if (result?.new) {
      dispatch(profileActions.success(result.new));
      if (loyalty?.coupons) {
        dispatch(profileActions.updateCoupons(loyalty.coupons));
      }
      dispatch(
        customerDetailsSuccess({
          ...result.old,
          availablePoints: loyalty?.availablePoints
        })
      );
    }
  };

  return {
    loading,
    error,
    profileData: profile,
    fetchCustomerDetails
  };
};

export default useCustomerDetails;
