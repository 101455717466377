import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SerializedError } from '@reduxjs/toolkit';
import {
  Cart, CartItem, CartItemInput, useAddCartItemMutation
} from '@pizza-hut-us-development/client-core';

import { AddableCartItem } from '@/api/phdApiV2Client/request.types';

import triggerMutationWrapper from '@/clientCore/helper/triggerMutationWrapper';
import {
  useCCTransformLegacyCartItem
} from '@/clientCore/temporaryTransformationalHooks/useCCTransformLegacyCartItem/useCCTransformLegacyCartItem';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import {
  presentationalCartSelectors
} from '@/clientCore/redux/selectors/clientCorePresentational/cart/presentationalCartSelectors';
import { findNewCartItemId } from '@/clientCore/redux/rail/helpers';
import { presentationalRailSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/rail/presentationalRailSelectors';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';
import { RootState } from '@/rootStateTypes';

interface AddCartItemPayload {
  item: AddableCartItem | CartItemInput;
  isLegacyBuilder?: boolean;
  onSuccess?: (cart: Cart, item: CartItem, newItemFound: boolean) => void;
  onError?: (error: SerializedError) => void;
}

export const useCartAddItem = () => {
  const [payloads, setPayloads] = useState<AddCartItemPayload[]>([]);
  const sortedItemPointers = useSelector(presentationalRailSelectors.sortedItemPointers);
  const cartId = useSelector(orderSelectors.cartId);
  const isCartLoading = useSelector(presentationalCartSelectors.isCartLoading);
  const isCartReady = cartId && !isCartLoading;

  const [addCartItem, { isLoading: isAddingCartItem }] = useAddCartItemMutation();
  const { handleLegacyCartItemTransformer } = useCCTransformLegacyCartItem();
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  // Debug
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);

  const handleAddCartItem = async (payload: AddCartItemPayload) => {
    if (!isCartReady) {
      // queue payloads to be processed once cart is ready
      setPayloads((value) => [...value, payload]);
      return;
    }

    const {
      item, isLegacyBuilder = false, onSuccess, onError
    } = payload;

    const newProduct: CartItemInput = isLegacyBuilder
      ? handleLegacyCartItemTransformer(item as AddableCartItem, isYumEcomm)
      : (item as CartItemInput);

    if (debugEnabled) {
      if (isLegacyBuilder) {
        transformationDebugLogging(
          'useCCTransformLegacyCartItem | useCartAddItem',
          `handleLegacyCartItemTransformer type:${'userSelections' in item ? 'DEAL' : item.type}`,
          item,
          newProduct,
          debugMode,
          [DebugModeOptions.CART, DebugModeOptions.PRODUCTS, DebugModeOptions.TRANSFORMATIONS]
        );
      }
    }

    await triggerMutationWrapper(addCartItem({ cartId, item: newProduct }), {
      onSuccess: (cart) => {
        const foundNewCartItemId = findNewCartItemId(sortedItemPointers, cart.items);

        // should always find the new cart item by ID, but fallback to last cart item if this fails
        const newItem = cart.items.find((cartItem) => (foundNewCartItemId ? cartItem.cartItemId === foundNewCartItemId : cartItem.id === newProduct.id)) ?? cart.items[cart.items.length - 1];
        onSuccess?.(cart, newItem, Boolean(foundNewCartItemId));
      },
      onError: (error) => {
        onError?.(error);
      }
    });
  };

  useEffect(() => {
    if (isCartReady && payloads.length) {
      payloads.forEach((payload) => handleAddCartItem(payload));
      setPayloads([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartReady]);

  return {
    handleAddCartItem,
    isAddingCartItem
  };
};
